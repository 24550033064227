import Aos from 'aos'

$(() => {
  Aos.init({
    once: true,
    throttleDelay: 200,
    offset: 250,
    duration: 600,
    delay: 200,
  })
})