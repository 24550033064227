$(() => {
  const is_pc = window.matchMedia('(min-width: 768px)')

  $('.card-service').on({
    'mouseenter': function(e) {
      if (is_pc.matches) {
        const $this = $(this)
        $this.addClass('active')
        $this.find('.card-service__inner').stop().slideDown()
      }
    },
    'mouseleave': function() {
      if (is_pc.matches) {
        const $this = $(this)
        $this.removeClass('active')
        $this.find('.card-service__inner').stop().slideUp()
      }
    },
  })
})