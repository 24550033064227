import { Offcanvas } from "bootstrap"
import { throttle } from "lodash"

$(() => {
  const navbarOffcanvas = new Offcanvas('#offcanvasNavbar')
  const $navbar = $('.navbar')
  const navbar_offcanvas = document.querySelector('#offcanvasNavbar')
  const $nav_link = $navbar.find('.navbar-nav .nav-link')

  navbar_offcanvas.addEventListener('show.bs.offcanvas', function() {
    $navbar.addClass('navbar-active')
  })

  navbar_offcanvas.addEventListener('hide.bs.offcanvas', function() {
    $navbar.removeClass('navbar-active')
  })

  $nav_link.filter('[href^="/#"]').on('click', function() {
    navbarOffcanvas.hide()
  })


})
