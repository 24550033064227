import { debounce } from 'lodash';

import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Parallax, Controller } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Parallax, Controller]);

export default class SwiperManager {
  constructor(selector, options, breakPoint = 768) {
    this.swiper = null
    this.swiperBool = false
    this.selector = selector
    this.options = options
    this.breakPoint = window.matchMedia(`(min-width: ${breakPoint}px)`)
    this.initialize();
  }

  initialize() {
    window.addEventListener('load', this.handleLoad);
    window.addEventListener('resize', debounce(this.handleResize), 150);
  }

  handleLoad = () => {
    if (this.breakPoint.matches) {
      this.swiperBool = false;
    } else {
      this.createSwiper();
      this.swiperBool = true;
    }
  };

  handleResize = () => {
    if (!this.breakPoint.matches && !this.swiperBool) {
      this.createSwiper();
      this.swiperBool = true;
    }else if (this.breakPoint.matches && this.swiperBool) {
      this.destroySwiper();
      this.swiperBool = false;
    }
  };

  createSwiper() {
    this.swiper = new Swiper(this.selector, this.options);
  }

  destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(false, true);
      this.swiper = null;
    }
  }
}

