import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Parallax, Controller } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay, Mousewheel, Parallax, Controller]);

import "./swiperManager"
import SwiperManager from './swiperManager';

$(() => {
  new Swiper('.swiper-fv', {
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: "bullets",
      clickable: true,
      renderBullet: function (index, className) {
        // ページネーションに番号を表示
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      },
    },
  })

  $('.section-contribution').each(function() {
    new Swiper(this.querySelector('.swiper-contribution'), {
      slidesPerView: 'auto',
      spaceBetween: 16,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
    })
  })
})
